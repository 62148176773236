<template>
  <v-card flat width="100%" :loading="loading" >
    <v-card-text>
      <v-card flat :width="width">
        <v-card-title class="font-weight-bold px-0 d-block">
          <back-button-component/>
          <div class="d-flex">
            <span class="font-weight-bold d-block">Profile information</span>
          </div>
        </v-card-title>
        <v-card-subtitle class="text-body-2 px-0">This information about yourself</v-card-subtitle>
        <v-card>
          <v-card-text class="px-0">
            <validation-observer ref="editProfile" v-slot="{ passes }">
              <v-form @submit.prevent="passes(submit)">
                <v-card-text>
                  <v-row class="pa-0">
                    <v-col class="py-0" cols="12" lg="6">
                      <v-hover v-slot="{ hover }">
                        <v-img :src="user['personal_data'].avatar ? user['personal_data'].avatar : require(`@/assets/avatar-${user['personal_data'].sex}.jpeg`)"
                               class="rounded-circle elevation-3"
                               style="position: relative"
                               :width="$vuetify.breakpoint.width / 4"
                               :height="$vuetify.breakpoint.width / 4">
                          <v-btn @click="openAvatarChange" width="60" height="60" fab dark color="primary"
                                 :style="`transition: .3s ease-in-out; position: absolute; bottom: 6px; left: calc(100% / 2 - 30px); z-index: 2; opacity: ${hover ? 1 : 0}`">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-img>
                      </v-hover>
                    </v-col>
                    <v-col class="py-0" cols="12" lg="6">
                      <validation-provider name="first name" rules="required" v-slot="{ errors }">
                        <v-text-field type="text"
                                      autocomplete="first_name"
                                      :error-messages="errors"
                                      label="First name" placeholder="Please, edit your first name" v-model="user.first_name"></v-text-field>
                      </validation-provider>
                      <validation-provider name="last name" rules="required" v-slot="{ errors }">
                        <v-text-field type="text"
                                      autocomplete="last_name"
                                      :error-messages="errors"
                                      label="Last name"
                                      persistent-placeholder
                                      placeholder="Please, edit your last name"
                                      v-model="user.last_name"></v-text-field>
                      </validation-provider>
                      <validation-provider name="username" rules="required|email" v-slot="{ errors }">
                        <v-text-field type="email"
                                      autocomplete="email"
                                      :error-messages="errors"
                                      v-model="user.email"
                                      label="E-mail"
                                      persistent-placeholder
                                      placeholder="Please, edit your e-mail"></v-text-field>
                      </validation-provider>
                      <validation-provider name="Sex" rules="required" v-slot="{ errors }">
                        <v-select v-model="user['personal_data'].sex"
                                  class="mt-n1"
                                  :items="sex"
                                  :error-messages="errors">
                          <template v-slot:label>
                            <label class="font-weight-bold">Change your sex</label>
                          </template>
                        </v-select>
                      </validation-provider>
                      <validation-provider name="password" rules="confirmed:confirmation" v-slot="{ errors }">
                        <v-text-field type="password"
                                      autocomplete="password"
                                      :error-messages="errors"
                                      v-model="user.password"
                                      label="Password"
                                      persistent-placeholder
                                      placeholder="Please, edit your password"></v-text-field>
                      </validation-provider>
                      <validation-provider name="password confirmation" vid="confirmation" v-slot="{ errors }">
                        <v-text-field type="password"
                                      autocomplete="password_confirmation"
                                      :error-messages="errors"
                                      v-model="user.password_confirmation"
                                      label="Password confirmation"
                                      persistent-placeholder
                                      placeholder="Please, confirm your password"></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="px-4">
                  <v-spacer></v-spacer>
                  <v-btn height="49" width="15%" rounded type="submit" color="primary">Save Profile</v-btn>
                </v-card-actions>
              </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-card>
    </v-card-text>
    <v-file-input ref="avatar"
                  @change="chooseFile"
                  accept="image/jpeg,png"
                  v-model="file"
                  style="position: absolute; width: 1px; height: 1px; left: -1000px"></v-file-input>
  </v-card>
</template>

<script>

export default {
  name: "Profile",
  data () {
    return {
      user: {},
      file: null,
      loading: false,
      sex: [
        {
          text: 'Female',
          value: 'female'
        },
        {
          text: 'Male',
          value: 'male'
        },
        {
          text: 'Other',
          value: 'other'
        }
      ]
    }
  },
  beforeMount() {
    this.user = this.$store.getters.GET_USER
  },
  methods: {
    submit () {
      this.loading = true
      this.$http.patch(`${process.env.VUE_APP_API_URL}/auth/me/update`, this.user)
      .then(() => {
        this.loading = false
      })
    },
    openAvatarChange () {
      this.$refs.avatar.$refs.input.click()
    },
    chooseFile(file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        // eslint-disable-next-line no-prototype-builtins
        this.user['personal_data'].avatar = reader.result
      }
      if (file) {
        reader.readAsDataURL(file)
      }
    }
  },
  computed: {
    width () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '80%'
        case 'sm': return '75%'
        case 'md': return '70%'
        case 'lg': return '85%'
        case 'xl': return '60%'
      }
      return '60%'
    }
  }
}
</script>

<style scoped>

</style>
