var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","width":"100%","loading":_vm.loading}},[_c('v-card-text',[_c('v-card',{attrs:{"flat":"","width":_vm.width}},[_c('v-card-title',{staticClass:"font-weight-bold px-0 d-block"},[_c('back-button-component'),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"font-weight-bold d-block"},[_vm._v("Profile information")])])],1),_c('v-card-subtitle',{staticClass:"text-body-2 px-0"},[_vm._v("This information about yourself")]),_c('v-card',[_c('v-card-text',{staticClass:"px-0"},[_c('validation-observer',{ref:"editProfile",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.submit)}}},[_c('v-card-text',[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"rounded-circle elevation-3",staticStyle:{"position":"relative"},attrs:{"src":_vm.user['personal_data'].avatar ? _vm.user['personal_data'].avatar : require(("@/assets/avatar-" + (_vm.user['personal_data'].sex) + ".jpeg")),"width":_vm.$vuetify.breakpoint.width / 4,"height":_vm.$vuetify.breakpoint.width / 4}},[_c('v-btn',{style:(("transition: .3s ease-in-out; position: absolute; bottom: 6px; left: calc(100% / 2 - 30px); z-index: 2; opacity: " + (hover ? 1 : 0))),attrs:{"width":"60","height":"60","fab":"","dark":"","color":"primary"},on:{"click":_vm.openAvatarChange}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","autocomplete":"first_name","error-messages":errors,"label":"First name","placeholder":"Please, edit your first name"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","autocomplete":"last_name","error-messages":errors,"label":"Last name","persistent-placeholder":"","placeholder":"Please, edit your last name"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"username","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","autocomplete":"email","error-messages":errors,"label":"E-mail","persistent-placeholder":"","placeholder":"Please, edit your e-mail"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Sex","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-n1",attrs:{"items":_vm.sex,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v("Change your sex")])]},proxy:true}],null,true),model:{value:(_vm.user['personal_data'].sex),callback:function ($$v) {_vm.$set(_vm.user['personal_data'], "sex", $$v)},expression:"user['personal_data'].sex"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","autocomplete":"password","error-messages":errors,"label":"Password","persistent-placeholder":"","placeholder":"Please, edit your password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password confirmation","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","autocomplete":"password_confirmation","error-messages":errors,"label":"Password confirmation","persistent-placeholder":"","placeholder":"Please, confirm your password"},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',{staticClass:"px-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"height":"49","width":"15%","rounded":"","type":"submit","color":"primary"}},[_vm._v("Save Profile")])],1)],1)]}}])})],1)],1)],1)],1),_c('v-file-input',{ref:"avatar",staticStyle:{"position":"absolute","width":"1px","height":"1px","left":"-1000px"},attrs:{"accept":"image/jpeg,png"},on:{"change":_vm.chooseFile},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }